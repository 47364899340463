
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
    <b-card title="Informations générales">
       <validation-observer ref="simpleRules">
        <b-form
          @submit.prevent
        >
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
             <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
              required
                id="basicInput"
                v-model="form.name"
                :plaintext="readOnly"
                placeholder="Nom"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Image"
              label-for="Photo"
            >
            
            <b-media vertical-align="top" v-if="file!==null">
                  <template #aside>
                    <b-img
                      :src="file"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-warning"
                      class="btn-icon"
                      @click="editPhoto()"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>
            <b-form-file v-else
            required
                v-model="file"
                :state="Boolean(file)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAdded($event)"
            />
           
            </b-form-group>
          </b-col>

          

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Code"
              label-for="Code"
            >
             <validation-provider
              #default="{ errors }"
              name="Prix"
              rules="required"
            >
              <b-form-input
              required
                id="Code"
                v-model="form.code"
                :plaintext="readOnly"
                :state="errors.length > 0 ? false:null"
                placeholder="#ffffff"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Langue par défaut"
              label-for="Langue par défaut"
            >
              <b-form-checkbox
                v-model="form.default_language"
                
                class="custom-control-primary"
              >
                Langue par défaut
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      </validation-observer>

    </b-card>
  <b-button v-if="edition==false"
     @click="createLanguage"
    >
    Enregistrer
    </b-button>

    <b-button v-else
     @click="editLanguage"
    >
    Enregistrer
    </b-button>


  </div>
</b-overlay>
</template>

<script>
import { BButton,BFormInput, BOverlay, BForm, BRow, BCol, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea,BCardText, BMedia, BImg} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {APIRequest} from '@simb2s/senseye-sdk'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  name: 'LanguageForm',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BCardText, 
    BMedia, 
    BImg,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm
  },
  directives: {
    Ripple,
  },
  data() {
      return {
          currentBase64:null,
          file: null,
          edition:false,
          loading:true,
          form:{
            name:"",
            code:'',
            image:'',
            default_language:false
          },
      }
  },
  props: ['language', 'readOnly'],
  methods:{
    editPhoto() {
      this.file=null
    },
    async createLanguage()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
        try {
          await APIRequest.create('languages',this.form)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'La langue a bien été créée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'languages'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },
    async editLanguage()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
      this.loading=true
        try {
        
          await APIRequest.update('languages',this.language.id,this.form)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'La langue a bien été modifiée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'languages'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },
    fileAdded(event)
    {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        
          let b64=reader.result.split(';')
          let type=b64[0]
          if(type.includes('image'))
          {
              let base64=b64[1]
              //base64.replace('base64,','')
              this.currentBase64=reader.result
              this.file=this.currentBase64
              this.form.image=this.currentBase64
          }
          else
          {
              this.file=null
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Ceci n'est pas une image !",
                  icon: 'AlertCircleIcon',
                  variant: 'warning',
                },
              })
          }
          
      };
    },
     
      
  },
  async mounted(){
    if(this.language!=null)
    {
      this.form=this.language
      this.file=this.language.image
      this.edition=true
    }
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>